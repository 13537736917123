<template>
  <div>
    <backup></backup>
    <restore></restore>
  </div>
</template>

<script>
import Backup from '../components/Backup.vue'
import Restore from '../components/Restore.vue'


export default {
  components: {
    Backup,
    Restore,
  },
}
</script>

<template>
  <div>
    <v-row class="d-flex justify-space-around py-5">
      <v-card 
        width="85%"
        outlined
        elevation="4"
      >
        <v-card-title>
         {{ $t('backup.title') }}
        </v-card-title>
        <v-card-text>
          <p class="px-3">{{ $t('backup.description') }}</p>
          <v-row class="px-3">
            <v-col>
              {{ $t('backup.settings_to_save') }}
              <ul>
                <li>{{ $t('backup.saved_settings_items.playlists') }}</li>
                <li>{{ $t('backup.saved_settings_items.scheduler_events') }}</li>
                <li>{{ $t('backup.saved_settings_items.artnet_devices') }}</li>
                <li>{{ $t('backup.saved_settings_items.universes') }}</li>
                <li>{{ $t('backup.saved_settings_items.main_settings') }}</li>
                <li>{{ $t('backup.saved_settings_items.user_settings') }}</li>
                <li>{{ $t('backup.saved_settings_items.timezone') }}</li>
              </ul>
            </v-col>
            <v-col>
              {{ $t('backup.settings_not_to_save') }}
              <ul>
                <li>{{ $t('backup.unsaved_settings_items.network_settings') }}</li>
                <li>{{ $t('backup.unsaved_settings_items.datetime_settings') }}</li>
                <li>{{ $t('backup.unsaved_settings_items.cue_files') }}</li>
              </ul>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end pa-4">
          <v-btn
            class="mb-2 primary--text"
            :disabled="!isAdministrator"
            @click="downloadBackupFile"
          >{{ $t('backup.backup_button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import Api from "@/api/api";

export default {
  computed: {
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
  },
  methods: {
    downloadBackupFile () {
      Api.downloadBackupFile()
      .then( response => {
        let fileName = response.headers['content-disposition'].split('filename=')[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }
  },
}
</script>

<style>

</style>
<template>
  <div>
    <v-row class="d-flex justify-space-around py-5">
      <v-card 
      width="85%"
      outlined
      elevation="4"
      >
        <v-card-title>
          {{ $t('restore.title') }}
        </v-card-title>
        <v-card-text>
          <div class="px-3">
            <p>{{ $t('restore.description') }}</p>
            <p>
              {{ $t('restore.description_warning') }}<br>
              {{ $t('restore.description_advice') }}<br>
            </p>
            <dl>
              <dt>{{ $t('restore.notice') }}</dt>
              <dd>{{ $t('restore.notice_detail') }}</dd>
            </dl>
          </div>
        </v-card-text>
        <v-card-actions
          class="justify-end pa-4"
        >
          <v-btn
            color="primary--text"
            :disabled="!isAdministrator && isLicenseValid"
            @click="uploadBackupDialog = true"
          >{{ $t('restore.restore_button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog persistent v-model="uploadBackupDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('restore.restore_card.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValid">
            <v-file-input
              accept=".backup"
              small-chips
              show-size
              v-model="file"
            ></v-file-input>
            <p v-if="errors.length">
              <b>{{ $t('restore.restore_card.error') }}:</b>
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </p>
          </v-form>
            
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary--text" @click="close">
            {{ $t('restore.restore_card.cancel_button') }}
          </v-btn>
          <v-btn :disabled="!isValid" color="primary--text" @click="uploadBackup">
            {{ $t('restore.restore_card.upload_button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/api"

export default {
  data: function() {
    return {
      uploadBackupDialog: false,
      isValid: false,
      file: null,
      errors: {},
    }
  },
  computed: {
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
  },
  methods:{
    close () {
      this.uploadBackupDialog = false
      this.$nextTick(() => {
        this.file = null
        this.errors = {}
      })
    },
    uploadBackup() {
      let formData = new FormData()

      formData.append('file', this.file)

      Api.uploadBackupFile(formData)
      .then(
        (resp) => {
          console.log(resp),
          this.close()
        }
      )
      .catch(error => {
        this.errors = error.response.data
      })
    },
  },
}
</script>

<style>

</style>